@tailwind base;
@tailwind components;
@tailwind utilities;

.layout {
  /*  1360px */
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.shadow-custom {
  box-shadow: 0px 2px 10px 0px rgba(49, 112, 181, 0.3);
}

.h-dashboard {
  height: calc(100vh);
}

.number-shadow {
  filter: drop-shadow(0px 10px 4px #b9b9b9);
}
.background-gradient {
  background: linear-gradient(96.96deg, #3170b5 1.45%, #002f62 47.6%);
}
